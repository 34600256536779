import {
    createContext,
    FC,
    memo,
    PropsWithChildren,
    useContext, useEffect,
    useState
} from "react";
import {User} from "../types/User";
import {authAPISignIn, authAPISignOut, getCurrentUser} from "../api/authApi";
import {redirect} from "react-router-dom";

interface Failure {
    code: number;
    message: {
        statusCode: number;
        message: string;
    }
}


type AuthContextType = {
    isReady: boolean;
    user: User | null;
    signIn: (username: string, password: string, callback: (success: { user: User } | null, failure: Failure | null) => void) => void;
    signOut: () => void;
};

const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider: FC<PropsWithChildren<{}>> = memo(({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isReady, setIsReady] = useState<boolean>(false);

    const signIn = async (username: string, password: string, callback: (success: { user: User } | null, failure: Failure | null) => void) => {
        const response = await authAPISignIn({ username, password });
        if (!!response) {
            if ((response.responseCode === 200 || response.responseCode === 204) && response.user) {
                setUser(response.user);
                callback({ user: response.user }, null);
                window.sessionStorage.setItem('User', JSON.stringify(response.user));
                window.sessionStorage.setItem('Access-Token', JSON.stringify(response.accessToken));
                window.sessionStorage.setItem('Refresh-Token', JSON.stringify(response.refreshToken));
            } else {
                callback(null, { code: response.responseCode, message: response.error });
            }
        }
    };

    const signOut = async () => {
        await authAPISignOut();
        setUser(null);
        window.sessionStorage.removeItem('User');
        window.sessionStorage.removeItem('Access-Token');
        window.sessionStorage.removeItem('Refresh-Token');
        redirect('/login');
    }

    useEffect(() => {
        getCurrentUser().then((user) => {
            if (!!user && !!user.id) {
                setUser(user);
                setIsReady(true);
            }
        }).catch(async () => {
            if (!user) {
                await signOut();
            }
        })
    }, []);

    const value = {
        isReady,
        user,
        signIn,
        signOut
    };

    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>
});

AuthProvider.displayName = 'AuthProvider';
export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
